<template>
  <div class="w-11/12 mb-4 bg-white rounded-xl shadow-lg text-gray-600">
      <div class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3" @click="toggleAccordion" style="border-bottom: gray solid 1px">
        <p class="text-dark text-left"><b>Monitoreo de la respuesta</b></p>
        <span class="h-5 w-5 m-2 flex justify-center items-center transition" :class="{'transform rotate-180': accordionOpen}">
          <img src="@/assets/icons/arrow-down.svg" alt="">
        </span>
      </div>
      <div v-if="accordionOpen" class="p-3">
         <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseInput type="text" label="Criterio" v-model="criterion.criterion_name" placeholder="00:00:00" :disabled="true"/>
                <BaseRadio ref="revised" name="revised" label="¿Revisado?" :radioOptions="optionsRevised" v-model="criterion.revised" :disabled="true"/>
            </div>
        </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseRadio ref="supplies" name="supplies" label="¿Insumo de supervisión?" :radioOptions="optionsSupplies" v-model="criterion.supplies" :disabled="true"/>
                <BaseInput type="text" label="Tiempo destinado para la gestión" v-model="criterion.evaluation_time" placeholder="00:00:00" :disabled="true"/>
            </div>
        </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseTextarea ref="describe" id="describe" label="Comentarios adicionales" placeholder="Mensaje" v-model="criterion.additional_comments" :disabled="true" maxlength="1000"/>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['complaint', 'criterion'],
  data(){
      return {
        form: {
          revised: 1,
          company: null,
          first_name: null,
          texto_queja: null
        },
        accordionOpen: false,
        optionsRevised: [
                {id: true, name: 'Sí'},
                {id: false, name: 'No'}
                ],
        optionsSupplies: [
                {id: true, name: 'Sí'},
                {id: false, name: 'No'}
                ]
      }
  },
  methods: {
      toggleAccordion(){
        this.accordionOpen = !this.accordionOpen; 
      }
  }
}
</script>

<style>

</style>