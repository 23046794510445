<template>
  <div class="w-11/12 mb-4 bg-white rounded-xl shadow-lg text-gray-600">
      <div class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer" @click="toggleAccordion" style="border-bottom: gray solid 1px">
        <p class="text-dark text-left"><b>Respuesta por parte de la entidad vigilada</b></p>
        <span class="h-5 w-5 m-2 flex justify-center items-center transition" :class="{'transform rotate-180': accordionOpen}">
          <img src="@/assets/icons/arrow-down.svg" alt="">
        </span>
      </div>
      <div v-if="accordionOpen" class="p-3">
        <h2 class="my-1 text-md text-left text-blue"><b>Respuesta</b></h2>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseInput type="text" label="Fecha de cierre" v-model="complaint.fecha_cierre" placeholder="" :disabled="true"/>
                <BaseInput type="text" label="Favorabilidad de cierre" v-model="complaint.aceptacion_queja" placeholder="" :disabled="true"/>
            </div>
        </div>
        <div class="w-11/12 grid grid-cols-1 gap-2">
                <BaseInput type="text" label="Aceptación" v-model="complaint.aceptacion_queja" placeholder="" :disabled="true"/>
            </div>
        <div class="w-11/12 grid grid-cols-1 gap-2">
                <BaseInput type="text" label="Retificación" v-model="complaint.rectificacion_queja" placeholder="" :disabled="true"/>
            </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseInput type="text" label="Desistimiento" v-model="complaint.desistimiento_nombre" placeholder="" :disabled="true"/>
                <BaseInput type="text" label="Admisión" v-model="complaint.admision" placeholder="" :disabled="true"/>
            </div>
        </div>
        <div class="w-11/12 grid grid-cols-1 gap-2">
                <BaseInput type="text" label="Marcación" v-model="complaint.marcacion" placeholder="" :disabled="true"/>
            </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseInput type="text" label="Tutela" v-model="complaint.tutela_nombre" placeholder="" :disabled="true"/>
                <BaseInput type="text" label="Queja exprés" v-model="complaint.expres" placeholder="" :disabled="true"/>
            </div>
        </div>
      <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseInput type="text" label="Instancia de recepción" v-model="complaint.insta_recepcion" placeholder="" :disabled="true"/>
                <BaseInput type="text" label="Punto de recepción" v-model="complaint.punto_recepcion" placeholder="" :disabled="true"/>
            </div>
        </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-1 gap-2">
                <BaseInput type="text" label="Documento de respuesta final" v-model="complaint.documentacion_final" placeholder="" :disabled="true"/>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['complaint'],
  data(){
      return {
        accordionOpen: false
      }
  },
  methods: {
      toggleAccordion(){
        this.accordionOpen = !this.accordionOpen; 
      }
  }
}
</script>

<style>

</style>